@import "../../styles/global/abstracts/include-media";

.dashboard-link {
  :hover {
    background-color: var(--color-neutral__accent-background);
  }

  a {
    color: var(--color-neutral__label);
    &.super-admin-link {
      color: var(--color-error__regular);
    }

    .dashboard-link-container {
      padding: 10px;
      border-radius: 8px;

      font-weight: normal;
      font-size: 1rem;

      display: flex;
      align-content: center;

      .material-icons-outlined {
        font-size: 1.125rem;
      }

      .link-icon {
        margin-right: 1rem;
      }
    }
    .dashboard-clickable-link-container {
      display: flex;
      align-content: center;
      width: 100%;
    }
  }
}

.menu-column {
  margin-bottom: 50px;
  padding: 0px 40px;

  .heading-container {
    display: flex;
    .dashboard-link-icon {
      font-size: 32px;
      text-align: right;
      color: var(--color-neutral__icon);
      margin-right: 16px;
    }

    .dashboard-menu-title {
      font-size: 1.4rem;
    }
  }
}

:root {
  --disabled-color: rgb(146, 164, 188);
  --superadmin-disabled-color: rgb(208, 167, 169);
}

.dashboard-link-text {
  width: 100%;
}

.apply-disabled-color {
  color: var(--disabled-color);
}

.apply-super-admin-disabled-color {
  color: var(--superadmin-disabled-color);
}

.apply-margin-for-icon {
  margin-right: 10px;
}
