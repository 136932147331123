.tool-tip-text {
  max-width: 400px;
  // width: 500px;
}

// Style elements inside the body text
.tool-tip-text > a {
  color: #64bbe1;
}

.tool-tip-text > b {
  font-weight: 900;
}
